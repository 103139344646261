<template>
  <div id="app">
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style></style>
